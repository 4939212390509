import React, { Component } from 'react';
import '../style.scss';
import { Link } from "react-router-dom";


import Slider from '../react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import '../slider.animations.css'



export default class HomeSlider extends Component{
  constructor(props){
      super(props);

      this.state = {
        slider: props.images
      }
      if (this.state.slider.length === 2) {
        this.state.slider.push(this.state.slider[0])
        this.state.slider.push(this.state.slider[1])
      }
  }
  scroll = () => {
    window.scrollTo({
      top: window.innerHeight*80/100,
      behavior: 'smooth'
    });
  }
  render(){
    return (
      <div className="slider-container">
          <Slider infinite={true} swipe={false} navigation={false} autoplay={4000}>
          {this.state.slider.map((slider, index) =><div className="slide" key={index}>
              <div className="slide-background color" style={{ background: `url('${window.$imagePath+slider.url}') no-repeat right center`, backgroundSize:'cover' }}></div>
              <div className="slide-content">
                
                  {  
                  // eslint-disable-next-line
                  !!(slider.title) ? <h1>{slider.title}</h1> : <h1></h1>}
                  {!!(slider.text) ? <p>{slider.text}</p> : <p></p> }
                  {!!(slider.link) ? <button className="button"><Link to={slider.link}><span>Devamını Gör</span></Link></button> : <br/>}
              </div>
          </div>)}
          </Slider>
          <div className="circle" onClick={this.scroll}></div>
        </div>
          /*<div className="numerator">
            <span className="active">01</span>
            <span>02</span>
            <span>03</span>
            <span>03</span>
          </div>*/
    );
  }
}
