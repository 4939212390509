import React, { Component } from 'react';
import { Link} from 'react-router-dom';


function showMenu(){
  const head = document.querySelector(".Menu-Wrapper");
  if(head.classList.contains("show")){
    head.classList.remove("show");
    document.querySelector(".Header-Bar").classList.remove("fixed");
    document.querySelectorAll(".sub-menu").forEach(function(element){
      if(element.classList.contains("active"))
        element.classList.remove("active");
    });
    if(document.querySelector(".rightMenu").classList.contains("activated")){
      document.querySelector(".rightMenu").classList.remove("activated");
      document.querySelector(".leftMenu").classList.remove("rightActive");
      document.querySelector(".goBack").classList.remove("active");
    }
  }else{
    head.classList.add("show");
    document.querySelector(".Header-Bar").classList.add("fixed");
  }
}
export default class Menu extends Component{
  constructor(props){
    super(props);
    
    this.state = {
      isLoaded:false,
      menus: []
    }
      
  }
  componentDidMount(){
    Promise.all([
        fetch(window.$api+'menu'),
    ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([menus]) => {
      this.setState({
        menus: menus,
        isLoaded: true
    }) })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
    document.querySelectorAll('.a').forEach(btn => btn.onclick = function() { window.scrollTo(0,0) })
}
  hoverOn(e){
    document.querySelectorAll(".sub-menu").forEach(function(element){
      if(element.classList.contains("active"))
      element.classList.remove("active");
    });

    if(document.querySelector('[data-id="'+e.target.getAttribute("data")+'"]')){
      //console.log(e.target.getAttribute("data"))
      document.querySelector('[data-id="'+e.target.getAttribute("data")+'"]').classList.add("active");
    }
  }
  menuClick = (e) => {
    document.querySelector(".rightMenu").classList.add("activated");
    document.querySelector(".leftMenu").classList.add("rightActive");
    document.querySelector(".goBack").classList.add("active");
    this.hoverOn(e)
  }
  goBack = () => {
    document.querySelectorAll(".sub-menu").forEach(function(element){
      if(element.classList.contains("active"))
      element.classList.remove("active");
      document.querySelector(".rightMenu").classList.remove("activated");
      document.querySelector(".leftMenu").classList.remove("rightActive");
      document.querySelector(".goBack").classList.remove("active");

    });
  }
  scroll = () => {
    showMenu()

    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
  render(){
      return (
        <section className="Menu-Wrapper">
          <Link className="menu-navigation" onClick={showMenu} to="#">
            <div className="content">
              <div className="act">
                <span></span>
                <span></span>
              </div>
              <span className="menu-text">MENU</span>
            </div>
          </Link>
          <div className="menu-inside">
            <section className="desktop">
            <span className="goBack" onClick={this.goBack}> <svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" viewBox="0 0 20 30" >
            <polygon fill="#000" points="20 15 2.228 0 0 3.626 12.954 15 0 26.374 2.228 30" transform="rotate(180 10 15)" />
            </svg> </span>
              <div className="leftMenu">
                <ul>
                    {this.state.isLoaded > 0 && this.state.menus.map((data,index) =>
                      <li key={data.id}><Link to={data.url} className="active a" onMouseEnter={this.hoverOn} onClick={data.children && this.menuClick} key={data.id} data={data.id}>{data.title}</Link></li>
                    )}
                </ul>
              </div>
              <div className="rightMenu">
                    {this.state.isLoaded > 0 && this.state.menus.map((data,index) =>
                        <ul data-id={data.id} key={index} className="sub-menu">
                            {!!(data.children) > 0 && data.children.map((e,i) =>
                              <li key={e.id}><Link to={e.url} onClick={this.scroll} data={data.id} key={data.id} className="a">{e.title}</Link></li>
                            )}
                        </ul>
                    )}
              </div>
              </section>
          </div>
        </section>
      );
    }
}

/*
 {this.state.menus.map((menu, index) =>
                    
                  )}
                  */