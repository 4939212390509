import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Footer extends Component{
  constructor(props){
    super(props);
    
    this.state = {
      isLoaded:false,
      menus: [],
      contact : []
    }
      
  }
  componentDidMount(){
    Promise.all([
        fetch(window.$api+'menu'),
        fetch(window.$api+'contact'),
    ])
    .then(([res1,res2]) => Promise.all([res1.json(),res2.json()]))
    .then(([menus,contact]) => {
      this.setState({
        menus: menus,
        contact : contact,
        isLoaded: true
    }) })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
    document.querySelectorAll('.a').forEach(btn => btn.onclick = function() { window.scrollTo(0,0) })
}
  render(){
    return(
      <div className="container --white">
        <div className="wrapper">
          <footer>
            <div className="--col-1">
              <Link to="/" className="logo"><img src="/logo.png" alt="" /></Link>
            </div>
            <ul className="--menu-list --col-3">
            {this.state.isLoaded > 0 && this.state.menus.map((data,index) =>
                <li className="--bold"><Link to={data.url}>{data.title}</Link></li>
            )}
             {this.state.isLoaded > 0 && this.state.menus.map((data,index) =>
                <>
                    {!!(data.children) > 0 && data.children.map((e,i) =>
                      <li key={e.id}><Link to={e.url} className="a">{e.title}</Link></li>
                    )}
                </>
              )}
            </ul>
            <div className="--col-1 --right">
              <p>{this.state.contact.address}</p>
              <p style={{fontWeight: 'bold'}}>Tüm Hakları Saklıdır.</p>
            </div>
          </footer>
        </div>
      </div>
    );
  }
}
