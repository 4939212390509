import React, { Component } from 'react'
import Button from './_Button'
import IosCheckmarkCircleOutline from 'react-ionicons/lib/IosCheckmarkCircleOutline'

export default class Appointment extends Component{
  constructor(props) {
      super(props);
      this.state = {
          errorMessage : "",
          isLoaded: false,
          form:{
            email:"",
            name:"",
            treat:"",
            phone:"",
            message:"",
            body:{}
          },
          isSubmitting: false,
          isError: false,
          submitted: false
      };
  }
  handleInputChange = e => {
    if(e.target.value && !e.target.classList.contains("not-empty")){
      e.target.classList.add("not-empty");
    }else if(!e.target.value && e.target.classList.contains("not-empty")){
      e.target.classList.remove("not-empty");
    }
    
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
    
  }
  submitForm = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });
     let body = {
          email: this.state.form.email,
          name: '[RANDEVU] ' + this.state.form.name,
          message: 'Telefon: ' +this.state.form.phone+"    Tedavi: "+this.state.form.treat+"     Mesaj: "+this.state.form.message 
        }
    const res = await fetch(window.$imagePath+"/ajax/contact/send", {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.setState({ isSubmitting: false });
    const data = await res.json();
    if(data.status!=="ok"){
      this.setState({ submitted: data.message , isError: true})

    }else{
      this.setState({ submitted: data.message });

      setTimeout(
        () =>
          {
            this.setState({
              isError: false,
              message: "",
              form: { email: "", name: "", phone:"", treat:"", message: "" }
            });
           
            }
          ,
        1
      );
    }

  };
  render(){
    return(
      <div className="Appointment no-margin">
         <div className="--form">
          <figure style={{background: 'url(/images/klinik/4.jpg)', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}></figure>
          <header>
            <h3 className="--blue">Ön Muayene Randevusu İçin</h3>
            <h2 className="--blue">Formu Doldurun</h2>
          </header>
        {this.state.submitted?
          <div style={{zIndex:9, position:'relative',textAlign:'center', color:'white', backgroundColor:'#fff', padding:'40px'}}>
            <IosCheckmarkCircleOutline fontSize={72} color={"#1B58A5"}/>
            <p style={{color:'#1b58a5'}}>{this.state.submitted}</p>
          </div>
        :
            <form onSubmit={this.submitForm}>
              <div className="--input-wrapper">
                <div className="--input flex">
                  <div className="flex-child">
                    <input type="text" id="name" name="name" onChange={this.handleInputChange} value={this.state.form.name} autoComplete="off"/>
                    <label htmlFor="name">Ad Soyad</label>
                  </div>
                  <div className="flex-child">
                    <input type="email" id="email" name="email" onChange={this.handleInputChange} value={this.state.form.email} autoComplete="off"/>
                    <label htmlFor="email">E-Posta</label>
                  </div>
                </div>
                <div className="--input flex">
                <div className="flex-child">
                  <input type="text" id="treat" name="treat" onChange={this.handleInputChange} value={this.state.form.treat}  autoComplete="off"/>
                  <label htmlFor="name">Tedavi</label>
                </div>
                <div className="flex-child">
                  <input type="phone" id="phone" name="phone" onChange={this.handleInputChange} value={this.state.form.phone} autoComplete="off"/>
                  <label htmlFor="email">Telefon</label>
                </div>
              </div>

                <div className="--input">
                  <textarea name="message" id="message" onChange={this.handleInputChange} rows="3" value={this.state.form.message}></textarea>
                  <label htmlFor="">Mesajınız</label>
                </div>
                <div className="--input">
                  <Button text="Formu Gönder" size="big withoutlink"/>
                </div>
              </div>
            </form>
        }
        </div>
      </div>
    );
  }
}
