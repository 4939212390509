import React, { Component } from 'react'
import Header from '../components/_Header.js'
import LatestBlogs from '../components/_LatestBlogs.js'

export default class Page extends Component{
  constructor(props){
      super(props);
      this.state = {
          url : props.match.params.url,      
          blog: [],  
          latest: [],
          errorMessage : "",
          isLoaded: false
      };
  }
  componentDidMount() {
    this.loadPage();
  }
  componentDidUpdate(prevProps, prevState){
    let currentUrl = this.props.match.params.url;
    if(prevProps.match.params.url !== currentUrl){

      this.setState({
        url: currentUrl,
        isLoaded: false
      }, () => {
        this.loadPage()
      });

    }
  }

  loadPage() {
    Promise.all([
      fetch(window.$api+'blog/'+this.state.url),
      fetch(window.$api+'blogs'),
      ])
      .then(([res1,res2]) => Promise.all([res1.json(),res2.json()]))
      .then(([blog,latest]) => {
        this.setState({
          blog: blog,
          latest: latest,
          isLoaded: true
      }) })
      .then(json => {
        setTimeout(() => this.setState({ isLoaded: true }), 1000);
      })
      .then(json => {
        if(this.state.isLoaded){
          document.querySelector(".Header-Bar").classList.remove("loading");
        }
      })
      .catch(error => {
          this.setState({ errorMessage: error });
          console.error('There was an error!', error);
      });
  }
  render(){
    return(
      <div>
          <Header/> 
          {this.state.isLoaded > 0 &&
            this.state.blog.status > 0 && <>
            <div className="container no-margin no-padding">
              <header className="flex-header">
                <div className="--title">
                  <section>
                    <h2>{this.state.blog.title}</h2>
                    <span className="seperator"/>
                  </section>
                </div>
                <figure>
                <img src={window.$imagePath+this.state.blog.cover} alt="blog" />
                </figure>
              </header>
            </div>
            <div className="container --bg-color no-padding no-margin">
                <article className="wrapper">
                  <section>
                    <span className="--date">{this.state.blog.created_at}</span>
                    <div className="--social-buttons"></div>
                  </section>
                    <div dangerouslySetInnerHTML={{__html: this.state.blog.dom}} />
                  </article>
            </div>
            {this.state.isLoaded > 0 && <LatestBlogs latest = {this.state.latest}/> }
            </> 
            
          }
         
          {this.state.isLoaded > 0 && this.state.blog.status < 1  &&
            <p>404</p>
          }
      </div>
    );
  }
}
