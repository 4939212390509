import React, { useState, useCallback } from 'react'

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import Content from "../contents/__images.json"

function Images(){
  const photos = Content.data;

  const [currentImage, setCurrentImage] = useState(0);
   const [viewerIsOpen, setViewerIsOpen] = useState(false);

   const openLightbox = useCallback((event, { photo, index }) => {
     setCurrentImage(index);
     setViewerIsOpen(true);
   }, []);

   const closeLightbox = () => {
     setCurrentImage(0);
     setViewerIsOpen(false);
   };

  return(
      <div className="wrapper">
      <h2 >Kliniğimizden Fotoğraflar</h2>
      <br></br>
      <br></br>
      <br></br>
      <Gallery photos={photos} onClick={openLightbox} direction={"column"} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={photos.map(x => ({
                ...x,
                srcset: x.srcSet,
                caption: x.title
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      </div>
  );
}
export default Images;
