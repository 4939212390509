import React, { Component } from 'react';
import './style.scss';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Index from './pages/Index.js';
import Page from './pages/Page.js';
import Blog from './pages/Blog.js';
import Treat from './pages/Treat.js';
import Footer from './components/_Footer'
import Contact from './pages/Contact.js'
import NotFound from './pages/NotFound.js'

window.$imagePath = 'https://panel.handankoprulubulut.com';
window.$api = 'https://panel.handankoprulubulut.com/api/';

export default class App extends Component {

  componentDidMount(){
      window.addEventListener('scroll', this.handleScroll);
     
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event){
      let scrollTop = window.pageYOffset;
      if(scrollTop>100){
        document.querySelector(".menu-navigation").classList.add("scrolling");
      }else{
        document.querySelector(".menu-navigation").classList.remove("scrolling");
      }

  }
  render(){
      return (
        <Router>
          <Switch>
              <Route exact path="/" component={Index} />
              <Route exact path="/iletisim" component={Contact} />
              <Route exact path="/blog/:url" component={Blog} />
              <Route exact path="/tedavi/:url" component={Treat} />
              <Route exact path="/:url" component={Page} />
              <Route  component={NotFound} status={404}/>

          </Switch>
          <Footer />

        </Router>
    );
  }
}
