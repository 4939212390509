import React, { Component } from 'react'
import Header from '../components/_Header.js'
import Button from '../components/_Button.js'
import {  Row, Col } from 'react-grid-system';
import IosCheckmarkCircleOutline from 'react-ionicons/lib/IosCheckmarkCircleOutline'

import IosMapOutline from 'react-ionicons/lib/IosMapOutline'
import IosMailOutline from 'react-ionicons/lib/IosMailOutline'
import IosCallOutline from 'react-ionicons/lib/IosCallOutline'
import {Helmet} from "react-helmet";

//import LogoWhatsapp from 'react-ionicons/lib/LogoWhatsapp'
export default class Contact extends Component{
  constructor(props) {
      super(props);
      this.state = {
          url : props.match.params.url,     
          page: [],
          errorMessage : "",
          isLoaded: false,
          form:{
            email:"",
            name:"",
            message:""
          },
          isSubmitting: false,
          isError: false,
          submitted: false
      };
  }

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate(prevProps, prevState) {
    let currentUrl = this.props.match.params.url;
    if(prevProps.match.params.url !== currentUrl){

      this.setState({
        url: currentUrl,
        isLoaded: false
      }, () => {
        this.loadPage()
      });

    }
  }

  loadPage() {
    Promise.all([
        fetch(window.$api+'contact'),
    ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([page]) => {
      this.setState({
        page: page,
        isLoaded: true
    }) })
    .then(json => {
      if(this.state.isLoaded){
        document.querySelector(".Header-Bar").classList.remove("loading");
      }
    })
    .then(json => {
      document.querySelector(".Menu-Wrapper").classList.remove("show");
    })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
  }

  handleInputChange = e => {
    if(e.target.value && !e.target.classList.contains("not-empty")){
      e.target.classList.add("not-empty");
    }else if(!e.target.value && e.target.classList.contains("not-empty")){
      e.target.classList.remove("not-empty");
    }
    
  
    this.setState({
      form: { ...this.state.form, [e.target.name]: e.target.value }
    });
  }
  submitForm = async e => {
    e.preventDefault();
    this.setState({ isSubmitting: true });

    const res = await fetch(window.$imagePath+"/ajax/contact/send", {
      method: "POST",
      body: JSON.stringify(this.state.form),
      headers: {
        "Content-Type": "application/json"
      }
    });
    this.setState({ isSubmitting: false });
    const data = await res.json();
    console.log(data)
    if(data.status!=="ok"){
      this.setState({ submitted: data.message , isError: true})

    }else{
      this.setState({ submitted: data.message })
    }
  

    setTimeout(
      () =>
        this.setState({
          isError: false,
          message: "",
          form: { email: "", name: "", message: "" }
        }),
      100
    );
  };
  render(){
    return(
      <div>
        <Helmet>
            <title>İletişim - Handan Köprülü Bulut</title>
            <meta name="description" content="Ankara Diş Hekimi Handan Köprülü Bulut | Randevu ve sorularınız için iletişime geçebilirsiniz"/>
        </Helmet>
      <Header />
        <div className="container no-margin no-padding">
          <header className="flex-header contact">
            <figure>
              <img src="/images/klinik/6.jpg" alt="blog" />
            </figure>
            <div className="--title">
              <section>
                <div className="__title">
                  <h2>Iletisimde Kalın</h2>
                  <span className="seperator"/>
                </div>
                <div className="contact-form">
                {this.state.submitted?
                    <div style={{zIndex:9, position:'relative',textAlign:'center', color:'white'}}>
                      <IosCheckmarkCircleOutline fontSize={72} color={"#1B58A5"}/>
                      <p style={{color:'#1b58a5'}}>{this.state.submitted}</p>
                    </div>
                  :
                  <form onSubmit={this.submitForm}>
                    <div className="--input-wrapper">
                      <div className="--input">
                        <input type="text" name="name" id="name" value={this.state.form.name} onChange={this.handleInputChange} autoComplete="off"/>
                        <label for="name">Adınız</label>
                      </div>
                      <div className="--input">
                        <input type="email" name="email" id="email" value={this.state.form.email} onChange={this.handleInputChange} autoComplete="off"/>
                        <label for="email">E-Posta Adresiniz</label>
                      </div>
                      <div className="--input">
                        <textarea name="message" id="message" onChange={this.handleInputChange} rows="3" value={this.state.form.message}></textarea>
                        <label for="message">Mesajınız</label>
                      </div>
                      <div className="--input">
                        <Button text="Gönder" size="big withoutlink"/>
                        {this.state.submitted ? <p>{this.state.submitted}</p> : <></>}
                      </div>
                    </div>
                  </form>}
                </div>
              </section>
            </div>
          </header>
        </div>
        <div className="container --bg-color no-padding no-margin --information">
            <Row style={{margin:0}}>
                <Col lg={4} sm={12}>
                  <section>
                    <Row style={{margin:0}}>
                      <Col xs={1} style={{margin:0, padding:0}} >
                        <IosMapOutline color="#0A4677"/>
                      </Col>
                      <Col xs={11} style={{margin:0, padding:0}}>
                        <p>{this.state.page.address}</p>
                      </Col>
                    </Row>
                    <Row style={{margin:0}}>
                      <Col xs={1} style={{margin:0, padding:0}} >
                        <IosCallOutline color="#0A4677"/>
                      </Col>
                      <Col xs={11} style={{margin:0, padding:0}}>
                        <p>{this.state.page.phone}</p>
                      </Col>
                    </Row>
                    <Row style={{margin:0}}>
                      <Col xs={1} style={{margin:0, padding:0}} >
                        <IosMailOutline color="#0A4677"/>
                      </Col>
                      <Col xs={11} style={{margin:0, padding:0}}>
                        <p>{this.state.page.mail}</p>
                      </Col>
                    </Row>
                    
                  </section>
                </Col>
                <Col lg={8} sm={12}>
                <iframe title="map" src={this.state.page.map} width="100%" height="450" frameborder="0" style={{border:'0'}} allowfullscreen=""></iframe>
                </Col>
            </Row>
        </div>
      </div>
    );
  }
}
