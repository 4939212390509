import React, { Component } from 'react'
import Treats from '../components/Treats.js'

import ServerStatus from 'react-server-status';

export default class NotFound extends Component{
  constructor(props){
      super(props);
      this.state = {
          treats: [],
          isLoaded: false
      };
  }
  componentDidMount() {
    this.loadPage();
  }
 
  loadPage() {
    Promise.all([
        fetch(window.$api+'tedaviler'),
    ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([treats]) => {
      this.setState({
        treats: treats,
        isLoaded: true
    }) })
    .then(json => {
      if(this.state.isLoaded){
        document.querySelector(".Header-Bar").classList.remove("loading");
      }
    })
    .then(json => {
      setTimeout(() => this.setState({ isLoaded: true }), 1000);
    })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
    }

  render(){
    return(
      <ServerStatus status={404}>
        <div className="not-found container">
          <div className="wrapper">
              <h3>Aradığınız sayfayı bulamadık</h3>
              <p>Tedavilerimize gözatmak ister misiniz?</p>
          </div>
          {this.state.isLoaded > 0 && <Treats treats = {this.state.treats}/> }
        </div>
      </ServerStatus>
    );
  }
}




