import React, { Component } from 'react';
import ReactCompareImage from 'react-compare-image';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default class CompareImage extends Component{
  constructor(props){
      super(props);

      let photos = props.photos;
      let images = photos.map((img) => img.image_after);

      this.state = {
        photos: photos,
        images: images
      }

      console.log(this.state)
  }

  render(){

    const responsive = {
      mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
      },
    };
    

    const CustomDot = ({
      index,
      onClick,
      active,
      carouselState: { currentSlide }
    }) => {
      return (
        <img className={active ? 'thumbnail active' : ' thumbnail inactive'} onClick={e => {
          onClick();
          e.preventDefault();
        }} src ={window.$imagePath+React.Children.toArray(this.state.images)[index]} alt ="" />

      );
    };
    return(
      <div className="container --bg-color --bg-image">
            <header>
              <h2>Öncesi/Sonrası</h2>
            </header>
            <section className="CompareImage">
            <Carousel responsive={responsive}
            slidesToSlide={1}
            draggable={false}
            focusOnSelect={false}
            minimumTouchDrag={80}
            swipeable={false}
            showDots={true}
            renderDotsOutside={true}
              customDot={<CustomDot />}
            partialVisibilityGutter ={40}
        >
          {this.state.photos.map((image, index) =>
            <div className="--image" key={index}><ReactCompareImage leftImage={window.$imagePath+image.image_before} rightImage={window.$imagePath+image.image_after} hover /></div>
          )}
        </Carousel>
      </section>
    </div>
    );
  }
}
