import React, { Component } from 'react'
import Header from '../components/_Header.js'
import Gallery from '../components/_Gallery'
import NotFound from '../pages/NotFound'

import {Helmet} from "react-helmet";
export default class Page extends Component{
  constructor(props) {
      super(props);
      this.state = {
          url : props.match.params.url,     
          page: [],
          errorMessage : "",
          isLoaded: false
      };
  }

  componentDidMount() {
    this.loadPage();
  }

  componentDidUpdate(prevProps, prevState) {
    let currentUrl = this.props.match.params.url;
    if(prevProps.match.params.url !== currentUrl){

      this.setState({
        url: currentUrl,
        isLoaded: false
      }, () => {
        this.loadPage()
      });

    }
  }

  loadPage() {
    Promise.all([
        fetch(window.$api+this.state.url),
    ])
    .then(([res1]) => Promise.all([res1.json()]))
    .then(([page]) => {
      this.setState({
        page: page,
        isLoaded: true
    }) })
    .then(json => {
      if(this.state.isLoaded){
        document.querySelector(".Header-Bar").classList.remove("loading");
      }
    })
    .then(json => {
       document.querySelector(".Menu-Wrapper").classList.remove("show");
       var _modules = ['accordion'];
            var _mPrefix = 'rgb-';
            var _mHandlers = {
                'accordion': function() {
                    let rootClass = _mPrefix + 'accordion';
                    let domNodes = document.getElementsByClassName(rootClass);
                    [...domNodes].forEach((m, i) => {
                        m.getElementsByClassName(rootClass + '__title')[0].onclick = _ => {
                            m.classList.toggle(rootClass + '--closed');
                            return false;
                        }
                    });
                }
            }

            // Init
            _modules.forEach((module, i) => {
                if(_mHandlers[module]) {
                    // console.log('Initiliazing ' + module + ' module');
                    _mHandlers[module]();
                }
            });


    })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
  }

  render(){
    return(
      <div>
        {this.state.isLoaded > 0 &&
            this.state.page.status > 0 ?
                <Helmet>
                  <title>{this.state.page.title} - Handan Köprülü Bulut</title>
                  <meta name="description" content={this.state.page.text.substring(0,70) + "... Handan Köprülü Bulut"}/>
              </Helmet>
              :
              <></>
        }
          <Header /> 
          {this.state.isLoaded > 0 &&
            this.state.page.status > 0 && <>
            <div className="container no-margin no-padding">
              <header className="flex-header">
                <div className="--title">
                  <section>
                    <h2>{this.state.page.title}</h2>
                    <span className="seperator"/>
                  </section>
                </div>
                <figure>
                <img src={window.$imagePath+this.state.page.cover} alt="blog" />
                </figure>
              </header>
            </div>
            <div className="container --bg-color no-padding no-margin">
                <article className="wrapper">
                  <section>
                    <div className="--social-buttons"></div>
                  </section>
                    <div dangerouslySetInnerHTML={{__html: this.state.page.dom}} />
                </article>
                {this.state.url==="hakkimda" ? <Gallery />: <></>}
            </div>
            </> 
          }
          {this.state.isLoaded > 0 && this.state.page.status < 1 &&
            <NotFound />
          }
      </div>
    );
  }
}
