import React, { Component } from 'react'
import '../style.scss';
import HomeSlider from '../components/_HomeSlider.js';
import CompareImage from '../components/_CompareImage.js';
import Treats from '../components/Treats.js'
//import LatestBlogs from '../components/_LatestBlogs.js'
import Appointment from '../components/_Appointment.js'
//import Testimonials from '../components/_Testimonials.js'
import Header from '../components/_Header'

import {Helmet} from "react-helmet";

export default class Index extends Component{
  constructor(props){
      super(props);
      this.state = {
          slider: [],
          latest: [],
          treats: [],
          photos: [],
          isLoaded: false
      };
  }
  componentDidMount() {
    this.loadPage();
  }
  componentDidUpdate(prevProps, prevState){
    let currentUrl = this.props.match.params.url;
    if(prevProps.match.params.url !== currentUrl){

      this.setState({
        url: currentUrl,
        isLoaded: false
      }, () => {
        this.loadPage()
      });

    }
  }

  loadPage() {
    Promise.all([
        fetch(window.$api+'slider'),
        fetch(window.$api+'blogs'),
        fetch(window.$api+'tedaviler'),
        fetch(window.$api+'beforeafter'),
    ])
    .then(([res1,res2,res3,res4]) => Promise.all([res1.json(), res2.json(),res3.json(),res4.json()]))
    .then(([slider,latest,treats,photos]) => {
      this.setState({
        slider: slider, 
        latest: latest,
        treats: treats,
        photos: photos,
        isLoaded: true
    }) })
    .then(json => {
      if(this.state.isLoaded){
        document.querySelector(".Header-Bar").classList.remove("loading");
      }
    })
    .then(json => {
      setTimeout(() => this.setState({ isLoaded: true }), 1000);
    })
    .catch(error => {
        this.setState({ errorMessage: error });
        console.error('There was an error!', error);
    });
    }

  render(){
    return(
      <div>
         {this.state.isLoaded > 0 ?
                <Helmet>
                  <title>Handan Köprülü Bulut - Diş Hekimi</title>
                  <meta name="description" content="Ankara Diş Hekimi - Handan Köprülü Bulut"/>
              </Helmet>
              :
              <></>
        }
        <Header/> 
        {this.state.isLoaded > 0 && <HomeSlider images={this.state.slider}/> }
        {this.state.isLoaded > 0 && <Treats treats = {this.state.treats}/> }
        {this.state.isLoaded > 0 && <CompareImage photos= {this.state.photos } />}
        <Appointment /> 
       
      </div>
    );
  }
}




