import React, { Component } from 'react';
import '../style.scss';
import { Container, Row, Col } from 'react-grid-system';
import { Link } from 'react-router-dom'
const wrapper = {
  title: 'TEDAVİLERİMİZ'
}


export default class Treats extends Component{
  constructor(props){
      super(props);

      this.state = {
        treats: props.treats
      }
  }
  render(){
    const imageStyle= {
      backgroundPosition:'center',
      backgroundRepeat : 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      height: '100%',
      top: 0 ,
      position:'absolute',
      
    }
    return(
      <div className="wrapper">
            <header>
                  <h2>{wrapper.title}</h2>
            </header>

        <Container fluid style={{ lineHeight: '32px' }} className="treats_container">
        <Row align="end">
        {this.state.treats.map((treat, index) =>
          <Col sm={12} md={6} lg={4} xl={3} className="--box" key={index}>
            <section>
              <h1>{index+1 < 10 ? 0 : <></>}{index+1}</h1>
              <figure>
                <div style={{...imageStyle, backgroundImage:`url('${window.$imagePath+treat.cover}?width=500')`}}></div>
              </figure>
              <div>
                <h3>{treat.title}</h3>
                <p>{treat.text.substring(0,70)}...</p>
                <Link to={"/tedavi/"+treat.url}>Devamını gör</Link>
              </div>
            </section>
          </Col>)}
        </Row>
      </Container>
          </div>
    );
  }
}
