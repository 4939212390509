import React, { Component } from 'react'

function But(props){
  const isLinked = props.link;
  if(isLinked){
    return(
      <button className={"button "+ props.size} {...props}>
        <div className="effect"></div>
          <a href={isLinked}>
            <span>{props.text}</span>
          </a>
      </button>
    );
  }
  return(
    <button className={"button "+ props.size} {...props}><div className="effect"></div><span>{props.text}</span></button>
  );
}
export default class Button extends Component{

  render(){
    return(
      <But {...this.props}/>
    );
  }
}
