import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Menu from './_Menu.js'
import { ThemeProvider } from '@zendeskgarden/react-theming';
import { Dots } from '@zendeskgarden/react-loaders';

export default class Header extends Component{
  constructor(props){
      super(props);

      this.state = {
        menus: props.menus,
        isLoaded: true
      }
  }
 /* componentDidUpdate(){
    if(this.state.isLoaded){
      document.querySelector(".Header-Bar").classList.remove("loading");
    }
  }*/
  render(){
    return(
    <div style={{width:'0', height:'0'}}>
        <header className="Header-Bar loading">
          <Link to="/" className="a"><img src="/logo.png" alt="esteticsa-logo" className="rgb-logo"/></Link>
          <ThemeProvider>
           <Dots color="#1B58A5" size="48px" className="loader"/>
          </ThemeProvider>
        </header>
         {<Menu isLoaded={this.state.isLoaded}/> }
    </div>
    );
  }
}
