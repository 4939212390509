import React, { Component } from 'react'
import Header from '../components/_Header.js'
import Treats from '../components/Treats.js'

import NotFound from '../pages/NotFound'
import {Helmet} from "react-helmet";
export default class Treat extends Component{
  constructor(props){
      super(props);
      this.state = {
          url : props.match.params.url,      
          treat: [],  
          treats: [],
          errorMessage : "",
          isLoaded: false
      };
  }
  componentDidMount() {
    this.loadPage();
  }
  componentDidUpdate(prevProps, prevState){
    let currentUrl = this.props.match.params.url;
    if(prevProps.match.params.url !== currentUrl){

      this.setState({
        url: currentUrl,
        isLoaded: false
      }, () => {
        this.loadPage()
      });

    }
  }

  loadPage() {
    Promise.all([
      fetch(window.$api+'tedavi/'+this.state.url),
      fetch(window.$api+'tedaviler'),
      ])
      .then(([res1,res2]) => Promise.all([res1.json(),res2.json()]))
      .then(([treat,treats]) => {
        this.setState({
          treat: treat,
          treats: treats,
          isLoaded: true
      }) 
    })
      .then(json => {
        if(this.state.isLoaded){
          document.querySelector(".Header-Bar").classList.remove("loading");
        }
      })

    .then(json => {
      var _modules = ['accordion'];
           var _mPrefix = 'rgb-';
           var _mHandlers = {
               'accordion': function() {
                   let rootClass = _mPrefix + 'accordion';
                   let domNodes = document.getElementsByClassName(rootClass);
                   [...domNodes].forEach((m, i) => {
                       m.getElementsByClassName(rootClass + '__title')[0].onclick = _ => {
                           m.classList.toggle(rootClass + '--closed');
                           return false;
                       }
                   });
               }
           }

           // Init
           _modules.forEach((module, i) => {
               if(_mHandlers[module]) {
                   // console.log('Initiliazing ' + module + ' module');
                   _mHandlers[module]();
               }
           });


   })
      .catch(error => {
          this.setState({ errorMessage: error });
          console.error('There was an error!', error);
      });
  }
  render(){
    return(
      <div>
        {this.state.isLoaded > 0 &&
            this.state.treat.status > 0 ?
                <Helmet>
                  <title>{this.state.treat.title} - Tedaviler - Handan Köprülü Bulut</title>
                  <meta name="description" content={this.state.treat.text.substring(0,70) + "... Handan Köprülü Bulut"}/>
              </Helmet>
              :
              <></>
        }
          <Header/> 
          {this.state.isLoaded > 0 &&
            this.state.treat.status > 0 && <>
            <div className="container no-margin no-padding">
              <header className="flex-header">
                <div className="--title">
                  <section>
                    <h2>{this.state.treat.title}</h2>
                    <span className="seperator"/>
                  </section>
                </div>
                <figure>
                <img src={window.$imagePath+this.state.treat.cover} alt="treat" />
                </figure>
              </header>
            </div>
            <div className="container --bg-color no-padding no-margin">
                <article className="wrapper">
                  <section>
                    <div className="--social-buttons"></div>
                  </section>
                    <div dangerouslySetInnerHTML={{__html: this.state.treat.dom}} />
                  </article>
            </div>
            {this.state.isLoaded > 0 && <Treats treats = {this.state.treats}/> }
            </> 
            
          }
         
          {this.state.isLoaded > 0 && this.state.treat.status < 1  &&
            <NotFound />
          }
      </div>
    );
  }
}
