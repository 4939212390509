import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


export default class LatestBlogs extends Component{
  constructor(props){
      super(props);

      this.state = {
        blogs: props.latest
      }
  }
  render(){
    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 720 },
          items: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
        },
      };
      return (
        <div className="container --max-width-big --latest">
        <header>
          <h2>Güncel Yayınlarımız</h2>
        </header>
        <Carousel responsive={responsive}
        slidesToSlide={1}
        draggable
        focusOnSelect={false}
        minimumTouchDrag={80}

        partialVisibilityGutter ={40}
    >
        {this.state.blogs.map((blog,index) =>
          <div className="blog-box" key={{index}}>
              <Link to={"/blog/"+blog.url} />
              <figure>
                <img src={window.$imagePath+blog.cover} alt="" />
              </figure>
              <section>
                <p>{blog.title}</p>
                <small>{blog.created_at}</small>
              </section>
          </div>
        )}
      </Carousel>
     </div>

    );
  }
}
